:root {
	/* Colors: */
	--white: #ffffff;
	--grey-field: #f8f8f8;
	--grey-background: #f5f5f5;
	--grey-1: #cfcfcf;
	--grey-2: #aeaeae;
	--darkgrey: #6e6e6e;
	--black: #202020;
	--blue: #2480dc;
	--purple: #840088;
	--orange: #e87400;
	--yellow: #ffb100;
	--yellow-o10: #ffb1001a;
	--yellow-o20: #ffb10033;
	--red: #d22a16;
	--red-o10: #d22a161a;
	--red-o20: #d22a1633;
	--green: #16b240;
	--green-o10: #16b2401a;
	--green-o20: #16b24033;
	--gold-yellow: #fff6e5;
	--gold-yellow-hover: #ffeabf;
}

html {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
    background-color: #F6F8FE;
}

body {
	height: 100vh;
	overflow-x: hidden;
    background-color: #F6F8FE !important;
}

#root,
#root > div {
	height: 100vh;
}

/* Width */
.w-90 {
	width: 90%;
}

/* Margin */
.margin-top-children-16 > * {
	margin-top: 16px;
}

/* Padding */
.padding-left-0 {
	padding-left: 0;
}

/* Modals */
.modal-footer-no-border-top > .modal-content > .modal-footer {
	border-top: none;
}

.modal-padding > .modal-content > .modal-body {
	padding: 24px 32px 0px 32px;
}

.modal-padding > .modal-content > .modal-footer {
	padding: 0px 32px 32px 32px;
}

/* General */
.space-children-24 > * {
	margin-right: 24px;
}
.space-children-24 > *:last-child {
	margin-right: 0px;
}

.space-children-12 > * {
	margin-right: 12px;
}
.space-children-12 > *:last-child {
	margin-right: 0px;
}

.space-children-32 > * {
	margin-right: 32px;
}
.space-children-32 > *:last-child {
	margin-right: 0px;
}

.space-between {
	justify-content: space-between;
}

/** Style **/
.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/** OVERRIDE CLASSI **/

.rbt-input-main {
	box-shadow: none !important;
	border-color: var(--grey-1) !important;
}

.rbt-aux {
	position: absolute;
	top: 0px;
	right: 6px;
}

.rbt-aux > button > .rbt-close-content {
	display: none;
}

.toast-container {
	z-index: 10000;
}

.toast {
	border-radius: 6px;
}

.toast-header {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	border-bottom: none;
	background-color: transparent;
}

.toast-header * {
	color: white;
}

.toast-header .btn-close {
	outline: 0;
	opacity: 1;
	box-shadow: none !important;
	color: white;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='white' d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
		center/1em auto no-repeat;
}

.toast-header .btn-close:hover {
	color: white;
	outline: 0;
	opacity: 1;
}

.toast-body {
	padding-top: 0px;
}

/** cursor **/
.cursor-default {
	cursor: default;
}

.cursor-pointer {
	cursor: pointer;
}

/** Hover **/
.hover-black:hover {
	color: var(--black) !important;
	transition: all 0.4s;
}

/** Animations **/
.rotate-180 {
	transform: rotate(180deg);
}





.min-width-1318 {
	min-width: 1318px;
}

.min-width-1301 {
	min-width: 1301px;
}

.min-width-1192 {
	min-width: 1192px;
}
.min-width-742 {
	min-width: 742px;
}

@media screen and (max-width: 1330px) {
	.scroll-1330 {
		min-width: 100%;
		overflow-x: scroll;
	}
}

@media screen and (max-width: 1440px) {
	.scroll-1440 {
		min-width: 100%;
		overflow-x: scroll;
	}
}

@font-face {
    font-family: "Light";  
    src: local("Poppins-Light"),
        url("./font/Poppins-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Regular";  
    src: local("Poppins-Regular"),
        url("./font/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Medium";  
    src: local("Poppins-Medium"),
        url("./font/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "SemiBold";  
    src: local("Poppins-SemiBold"),
        url("./font/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Bold";  
    src: local("Poppins-Bold"),
        url("./font/Poppins-Bold.ttf") format("truetype");
}

.light-font {
    font-family: "Bold";
}

.white-box {
    background-color: var(--white);
    border-radius: 28px;
    padding:32px;
    box-shadow: 0px 5px 25px #3444721A;
	height: 100%;
}

::placeholder{
    color: #7585b5;
    opacity: 1; /* Firefox */
}

.css-1jqq78o-placeholder{
    color: #7585b5 !important;
    opacity: 1; /* Firefox */
	font-family: "Regular";
	font-size: "14px";
}

.css-1dimb5e-singleValue{
    color: #344472 !important;
    opacity: 1;
}

.css-1nmdiq5-menu{
    font-family: "Regular";
    font-size: "16px";
    color:#344472 !important;
}

.btn-yellow{
    background-color:#f7be62;
    height:52px;
    width: 200px;
    padding:14px 32px;
    border-radius:28px;
    border: 1px solid #f7be62;
    font-family: "SemiBold";
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-csv{
    background-color:#344472;
    height:52px;
    width: 215px;
    padding:14px 32px;
    border-radius:28px;
    border: 1px solid #344472;
    font-family: "SemiBold";
    font-size: 16px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.back-box {
    
	background: url('./images/Illustrazione3.png');
	background-repeat: no-repeat;
	background-color: #DDE4F9;
	background-size: contain;
    border-radius: 30px;
    padding:0px;
    box-shadow: 0px 5px 25px #3444721A;
    
}



@media screen and (max-width: 1090px) {
	.font-resize {
		font-size: 3vw !important;
  	}
}

@media screen and (max-width: 1044px) {
	.text-nav-responsive{
		display:none !important;
	}
}

@media screen and (max-width: 1000px) {
	.mobile2-box{
		display: block !important;
	}

	.desktop2-box{
		display: none !important;
	}
}

@media screen and (max-width: 768px) {

	.responsive-padding {
		padding: 10px 0px !important;
		margin-top: 110px !important;
	}
	.responsive-block-padding {
		padding: 0px 50px !important;
	}
	.text-responsive{
		display:none !important;
	}
	.responsive-nav-margin{
		margin-left: 20px !important;
	}
	.responsive-logo-margin{
		margin-left: 16px !important;
	}
	.search-icon-responsive{
		display: block !important;
	}
	.info-icon-responsive{
		width: 22px !important;
		height: 22px !important;
		margin-right: 16px !important;
		margin-left: 16px !important;
	}
	.desktop-box{
		display: none !important;
	}

	.mobile-box{
		display: block !important;
	}
	.back-box{
		border-radius: 29px !important;
	}
	.legend-responsive{
		display: block !important;
	}
	.info-legend-responsive{
		display: none !important;
	}
	.filter-responsive{
		text-align: center !important;
	}
	.menu-height-responsive{
		height: 45vh !important;
	}
	.center-responsive{
		text-align: center !important;
		width: 100% !important;
		padding-left: 12px;
		padding-right: 12px;
	}
	.bar-chart-responsive{
		margin-left: -48px !important;
		margin-right: -48px !important;
	}
	.treemap-chart-responsive{
		margin-left: -48px !important;
		margin-right: -48px !important;
		/*margin-bottom: -36px !important;*/
		
	}
	.font-resize {
		font-size: 7vw !important;
  	}

	.filter-responsive-width{
		min-width: 47% !important;
		margin-top: 6px;
	}

	.csv-btn-responsive{
		justify-content: center !important;
	}

	.find-btn-responsive{
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: auto !important;
		margin-bottom: 24px !important;
	}

	.subtitle-font-responsive{
		font-size: 16px !important;
	}

	.stat-one-row-responsive{
		padding: 0px !important;
		margin-top: 12px !important;
	}

	.box-stat-height-responsive{
		height: 70px !important;
	}

	.zero-padding-responsive{
		padding: 0px !important;
	}

	.stat-two-row-responsive{
		padding-top: 16px !important;
		padding-bottom: 16px !important;
	}

	.justify-center-responsive{
		justify-content: center !important;
	}

	.margin-bottom-responsive{
		margin-bottom: 10px !important;
	}

	.margin-bottom-white-responsive{
		margin-bottom: 40px !important;
	}

	.margin-top-responsive{
		margin-top: 50px !important;
	}

	.margin-top-asl-responsive{
		margin-top: 20px !important;
	}

	.margin-top-white-responsive{
		margin-top: 4px !important;
	}

	.margin-top-footer-responsive{
		margin-top: 10px !important;
	}
}

@media screen and (max-width: 1350px) and (min-width: 769px) {
	.resp-1350{
		width:50% !important;
	}
	.row-stat-1350{
		width:50% !important;
	}
	.row-stat-filter-1350{
		width:100% !important;
	}
	.filter-resp-1350{
		display: none !important;
	}
}

.custom-tooltip{
	background-color: #16b240;
	opacity: 1;
	font-family: "SemiBold";
    font-size: 12px;
    color: white;
	
}

.tooltip-border{
	border: 2px solid #DDE4F9;
	border-radius: 16px;
}

.tooltip-grey-font{
	font-family: "Medium";
	color: #817C9B;
	font-size: 12px;
}

.tooltip-yellow-font{
	font-family: "SemiBold";
	color: #D5942C;
	font-size: 12px;
}

.no-margin{
	margin: 0px;
}

.fullrounded{
	border-radius: 5px !important;
}

.my-class-test{
	font-family:"Regular !important";
	font-size: 12px;
	color: #D5942C !important;
}